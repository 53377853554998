.article {
  max-width: 1200px;
  margin: auto;
  padding: 2em;
  font-family: 'Georgia', serif;
  line-height: 1.7;
  color: #333;
  font-size: 18px;
}

.article-title {
  font-size: 2.5em;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 1em;
}

.section {
  margin-bottom: 2em;
  text-align: left;
}

.sub-title {
  font-size: 2em;
  color: #2c3e50;
  margin-bottom: 1em;
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 1em;
}

.book-card {
  width: 400px;
  height: auto;
  margin-bottom: 1em;
}

ul {
  list-style: disc inside none;
  margin-bottom: 2em;
}

.section p {
  margin-bottom: 1em;
}