.extension-advertisement {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

header {
  background-color: #f2f2f2;
  padding: 20px 0;
}

.extension-details {
  margin-top: 10px;
}

.extension-image img {
  max-width: 80%;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.extension-description {
  margin-top: 10px;
  margin-bottom: 30px;
}

.extension-description h2 {
  color: #333;
}

.extension-description p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.extension-description ul {
  list-style: none;
  padding-left: 0;
}

.extension-description li {
  margin-bottom: 10px;
}

.download-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #2980b9;
}

footer p {
  margin-top: 60px;
  color: #888;
}